import * as React from "react"
import styled from 'styled-components'
import parse from "html-react-parser"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { color, media } from './Styles'

const BlogPost = ({ post }) => {
    const image = {
        image: getImage(post.featuredImage?.node?.localFile),
        alt: post.featuredImage?.node?.altText || '',
    }

    return(
        <BlogPostStyled>
            <article className='wrapper'>
                <div className='content'>
                    {image?.image &&
                        <div className='image-container'>
                            <GatsbyImage image={image.image} alt={image.alt || ''} className='image'/>
                        </div>
                    }
                    <section className='section-content'>
                        <div className='title'>
                            <h2>{parse(post.title)}</h2>
                            <span className='subtitle'>{parse(post.excerpt)}</span>
                        </div>
                    </section>
                </div>
                <div className='description'>
                    {parse(post.content)}
                </div>
            </article>            
        </BlogPostStyled>
    )
}

export { BlogPost }

const BlogPostStyled = styled.div`
    .wrapper{
        max-width:1920px;
        margin:0 auto;
        padding:clamp(30px,15vw,80px) 5%;
        color:${color.black};
        
        .content{
            display:grid;
            grid-template-columns:repeat(2,1fr);
            gap:5%;

            ${media.l`
                grid-template-columns:1fr;
                gap:2rem;
            `}

            .image-container{
                position:relative;
                
                .image{
                    height:100%;
                    max-height: 550px;
                    width: 100%;
                    margin-left: auto;
                }
            }

            .section-content{
                display:flex;
                flex-direction:column;
                justify-content:center;
                gap:3rem;

                ${media.l`
                    gap:2rem;
                `}

                .title{
                    h2{
                        font-weight: 700;
                        font-size: 3rem;
                        text-transform: uppercase;
                        margin-bottom:0.5rem;
                    }

                    .subtitle{
                        font-weight:600;
                        font-size:1.3rem;
                        margin-bottom:1rem;
                        color:#5c5a5a;

                        ${media.m`
                            font-size:1.3rem;
                        `}
                    }
                } 
            }
        }

        .description{
            line-height:1.8rem;
            max-width: 1200px;
            margin: 4rem auto;
        }

        .button{
            margin:0 auto;
        }
    }
`