import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { BlogPost } from '../components/BlogPost'
import PageTitle from '../components/PageTitle'
import { useTranslation } from "react-i18next"


const BlogPostTemplate = ({ data }) => {  

  const { t } = useTranslation()

  return (
    <Layout data={t("home", { returnObjects: true })}>
      <Seo title={data.post.title} description={data.post.excerpt} />
      <PageTitle
        title={data.post.title}
        subtitle={data.post.date}
      />
      <BlogPost
        post={data.post}
      />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query blogTemplate($id: String!, $language: String!) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "dddd | DD MMMM YYYY", locale: "pt")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
                gatsbyImageData(
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                    formats: [AUTO, WEBP, JPG]
                    quality: 100
                )
            }
          }
        }
      }
    }

    locales: allLocale(filter: {ns: {in: ["about", "home", "products"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
